'use strict';

$(function () {
    $('a[href*="#"]').on('click',function(e){
        var t= $(this.hash);
        t=t.length&&t||$('[name='+this.hash.slice(1)+']');
        if(t.length){
            var tOffset=t.offset().top;
            $('html,body').animate({scrollTop:tOffset-40},'fast');
            e.preventDefault();
        }
    });
    $('#year').text(new Date().getYear() + 1900);
});
